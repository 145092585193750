import moment from 'moment';
import {getRequest, postRequest} from 'utils/http';

const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;


const transformSurveyRequestlist = function (response) {
  if (response.code === 0) {
    const surveyRequestList = response.data.map((item, index) => {
      return {
        ...item,
        stt: index + 1,
        text: item.rqName,
        value: item.rqId,
        key: item.rqId,
        createDate: moment(new Date(item.createTime)).format('DD/MM/yyyy HH:mm:ss'),
        requestTime: moment(new Date(item.rqTime)).format('DD/MM/yyyy HH:mm:ss'),
      }
    });
    response.surveyRequestList = surveyRequestList;
  }
  return response;
};

const autocompleteListService = function (response) {
  if (response.code === 0) {
    const list = response.data.map((item, index) => {
      return {
        id: item.id,
        value: item.sname,
      }
    });
    response.aucompleteListService = list;
  }
  return response;
};

const transformListUser = function (response) {
  if (response.code === 0) {
    const list = response.data.map((item, index) => {
      return {
        ...item,
        key: item.id,
        createDate: moment(new Date(item.createTime)).format('DD/MM/yyyy HH:mm:ss'),
      }
    });
    response.listUser = list;
  }
  return response;
}

const transformUserGroupList = function (response) {
  if (response.code === 0) {
    const list = response.data.map((item, index) => {
      return {
        ...item,
        key: item.id,
        text: item.ugName,
        value: item.id,
        createDate: moment(new Date(item.createTime)).format('DD/MM/yyyy HH:mm:ss'),
      }
    });
    response.listUserGroup = list;
  }
  return response;
}

const transformUserGroupListId = function (response) {
  if (response.code === 0) {
    const list = response.data.map((item, index) => {
      return {
        ...item,
        key: item.id,
        text: item.ugName,
        value: item.id,
        createDate: moment(new Date(item.createTime)).format('DD/MM/yyyy HH:mm:ss'),
      }
    });
    response.listUserGroupByID = list;
  }
  return response;
}

export const UserService = {
  login: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/login`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  addSurveyRq: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/survey-request/add`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  updateSurveyRq: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/survey-request/update`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  getSurveyRq: function (successCallback, failCallback) {
    const URL = `${baseURL}/survey-request/all`;
    return getRequest(URL, {}, successCallback, failCallback, transformSurveyRequestlist);
  },

  getService: function (successCallback, failCallback) {
    const URL = `${baseURL}/service/all`;
    return getRequest(URL, {}, successCallback, failCallback, autocompleteListService);
  },

  deleteSurveyRq: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/survey-request/delete/${data?.id}`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  getUserAll: function (successCallback, failCallback) {
    const URL = `${baseURL}/user/all`;
    return getRequest(URL, {}, successCallback, failCallback, transformListUser);
  },

  getUserByID: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/${data?.id}`;
    return getRequest(URL, data, {}, successCallback, failCallback, transformListUser);
  },

  createUser: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/add`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  updateUser: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/update`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  changePassword: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/change-password`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  updateUserStatus: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/update/${data.id}/${data.status}`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  deleteUser: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user/delete/${data?.id}`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  getUserGroupList: function (successCallback, failCallback) {
    const URL = `${baseURL}/user-group/all`;
    return getRequest(URL, {}, successCallback, failCallback, transformUserGroupList);
  },

  getUserGroupListByID: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user-group/${data?.id}`;
    return getRequest(URL, data, {}, {}, successCallback, failCallback, transformUserGroupListId);
  },

  createUserGroup: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user-group/add`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  updateUserGroup: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user-group/update`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  updateUserGroupStatus: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user-group/update/${data.id}/${data.status}`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  deletUserGroup: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/user-group/delete/${data?.id}`;
    return postRequest(URL, {}, {}, successCallback, failCallback);
  },
}

