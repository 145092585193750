import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {FORBIDDEN, FORGOT_PASSWORD, HOME_PAGE, LOGIN, PUBLIC} from 'global/routes';
import Login from './util/Login';
import ForgotPassword from './util/ForgotPassword';
import MainPage from './MainPage';
import {useSelector} from 'react-redux';
import PublicExperiencePage from "./public/PublicExperiencePage";
import Page404 from "./error/Page404";
import Page403 from "./error/Page403";

function AppWrapper() {
    const authorized = useSelector(state => state.userReducer.authorized);
    const location = useLocation();
    const getReactComponent = () => {
        // console.log(location.pathname)
        // const redirectUrl = new URLSearchParams(location.search).get('redirectUrl');
        const redirectUrl = location.pathname;
        const urlRedirect = `${LOGIN}?redirectUrl=${redirectUrl}`
        return !authorized ? <Redirect to={urlRedirect}/> : <MainPage/>
    }
    return (
        <Switch>
            <Route
                path={FORGOT_PASSWORD}
                component={ForgotPassword}
                exact
            />

            <Route path={PUBLIC}>
                <PublicExperiencePage/>
            </Route>

            <Route path={LOGIN} exact>
                {authorized ? <Redirect to={HOME_PAGE}/> : <Login/>}
            </Route>

            <Route path={HOME_PAGE}>
                {
                    getReactComponent
                }
            </Route>
            <Route path={FORBIDDEN}>
                <Page403/>
            </Route>
            <Route path="*">
                <Page404/>
            </Route>
        </Switch>
    );
}

export default AppWrapper;
