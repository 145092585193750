import moment from 'moment';
import {getRequest} from "../utils/http";

export const CommonService = {
    REGEX_PHONE_NUMBER: /^\+?(?:[0-9]??).{9,12}[0-9]/,

    REGEX_EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    REGEX_ONLY_NUMBER: /^[0-9]*$/,

    REGEX_MAX_LENGTH: /^.{1,150}$/,

    getCovertedTime: function (date, FORMAT_TIME) {
        return moment(date).format(FORMAT_TIME || "DD/MM/YYYY");
    },

    downloadFile: (url, filePath) => {
        const fileName = filePath?.replace(/^.*[\\/]/, '') || "";
        const URL = url + filePath;
        return getRequest(URL, {responseType: 'blob'}, (res) => {
            const url = window.URL.createObjectURL(new Blob([res]),);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName,);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
            window.URL.revokeObjectURL(url)

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }, (error) => {
            console.log(error)
        });
        // window.location.href = URL
    },
}
