import React, {useState} from 'react';
import {Checkbox, Form, Select} from 'antd';
import OutsideClick from 'components/common/OutsideClick/OutsideClick';
import SurveyFormModal from 'components/page/survey/SurveyFormModal';
import UserAddModalProfile from 'pages/setting/user-group/components/UserAddModalProfile';
import {InputForm} from 'components/common';

const { Option } = Select;

const UserManagementEditProfile = ({
  editUserInfo,
  isEditData,
  reset,
  currentUserGroup,
  setCurrentUserGroup,
  userInfo,
  onChangeChooseOriginUserGroup,
}) => {
  const [countCheckRole, setCountCheckRole] = useState(0);
  return (
    <SurveyFormModal
      title="Thông tin tài khoản"
      confirmButton="Cập nhật"
      confirmAction={editUserInfo}
      closeButton="Đóng"
      isOpenModal={isEditData}
      size="lg"
      formValueEdit={userInfo}
      content={
        <div className="grid grid-cols-1 gap-4">
          <div className="grid grid-cols-1 gap-4 grid-cols-3">
            <div style={{ pointerEvents: 'none' }}>
              <p className="-m-0">Tên người dùng</p>
              <InputForm name="name" placeholder="Tên người dùng" />
            </div>
            <div style={{ pointerEvents: 'none' }}>
              <p className="-m-0">Email</p>
              <InputForm name="email" placeholder="Email" />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 grid-cols-1">
            <div>
              <p className="-m-0" style={{ color: 'blue' }}>
                Để thay đổi mật khẩu, hãy nhập Mật khẩu hiện tại của bạn, sau đó nhập Mật khẩu mới và Nhập lại mật khẩu
                mới!
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 grid-cols-3">
            <div>
              <p className="-m-0">Mật khẩu hiện tại</p>
              <InputForm isPassword name="oldPassword" placeholder="Mật khẩu hiện tại" />
            </div>
            <div>
              <p className="-m-0">Mật khẩu mới</p>
              <InputForm isPassword name="newPassword" placeholder="Mật khẩu mới" />
            </div>
            <div>
              <p className="-m-0">Nhập lại mật khẩu mới</p>
              <InputForm isPassword name="retypeNewPassword" placeholder="Nhập lại mật khẩu mới" />
            </div>
          </div>
          <div className="grid grid-cols-2 flex justify-end" style={{ pointerEvents: 'none' }}>
            <p className="-m-0"> Đơn vị yêu cầu</p>
            <Select className="ml-2" onChange={(e) => onChangeChooseOriginUserGroup(e)} value={userInfo?.orgId}>
              {userInfo?.groupList?.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.ugName}
                </Option>
              ))}
            </Select>
          </div>
          <Form.Item name="viewDataGroup" valuePropName="checked">
            <Checkbox style={{ pointerEvents: 'none' }}>Xem tất cả đơn vị</Checkbox>
          </Form.Item>
          {userInfo?.groupList?.map((item, index) => (
            <div
              key={index}
              className="mt-2 pt-2 border border-dashed border-l-0 border-r-0 border-b-0 border-indigo-500"
            >
              <OutsideClick onClickOutSide={() => setCurrentUserGroup(-1)}>
                <UserAddModalProfile
                  item={item}
                  index={index}
                  listUserGroup={userInfo?.groupList || []}
                  currentUserGroup={currentUserGroup}
                  onClick={() => setCurrentUserGroup(index)}
                  userInfo={userInfo}
                  countCheckRole={countCheckRole}
                  setCountCheckRole={setCountCheckRole}
                />
              </OutsideClick>
            </div>
          ))}
        </div>
      }
      closeModal={() => {
        reset(true);
        setCountCheckRole(0);
      }}
    />
  );
};

export default React.memo(UserManagementEditProfile);
