import axios from 'axios';
import {cui, Notification} from 'components/common';
import {initializeStore} from './../redux';
import Cookies from 'universal-cookie';
import {message} from "antd";

const cookies = new Cookies();
const https = require('https');
axios.defaults.httpsAgent = new https.Agent({rejectUnauthorized: false});
axios.defaults.timeout = 120000;

function handleHttpError(error) {
    if (error.response && error.response.data) {
        return error.response.data.errorMessage || error;
    }
    return error;
}

function transformConfig(config) {
    const requestTime = Date.now();
    const token = cookies.get('token') || '';
    const tokenPublic = cookies.get('AuthorizationPublic') || '';

    const headers = {
        'Content-Type': 'application/json',
        RequestTime: requestTime,
        Authorization: `Bearer ${token}`,
        AuthorizationPublic: `Bearer ${tokenPublic}`,
        ...config.headers,
    };

    config.headers = headers;
    config.timeout = 120000;
    return config;
}

function makeHttpRequest(apiCall, successCallBack, failCallBack, transformFunc) {
    const store = initializeStore();
    store.dispatch({type: 'SHOW_PROGRESS'});
    const state = store.getState();

    return new Promise(async () => {
        try {
            const response = await apiCall();
            store.dispatch({type: 'HIDE_PROGRESS'});
            if (response?.data?.error_code === '401' && state?.authorizedReducer?.authorized) {
                Notification.error('Phiên làm việc đã hết hạn, Vui lòng đăng nhập lại');
                store.dispatch({type: 'LOGOUT_ACTION'});
                localStorage.clear();
                return;
            }
            const responseData = response.data;
            const successResponse = cui.isFunction(transformFunc) ? transformFunc(responseData) : responseData;
            successCallBack(successResponse);
        } catch (e) {
            if (cui.isFunction(failCallBack)) {
                failCallBack(handleHttpError(e));
                store.dispatch({type: 'HIDE_PROGRESS'});
            }
        }
    });
}

function makeHttpRequestNoLoading(apiCall, successCallBack, failCallBack, transformFunc) {
    const store = initializeStore();
    const state = store.getState();

    return new Promise(async () => {
        try {
            const response = await apiCall();
            if (response?.data?.error_code === '401' && state?.authorizedReducer?.authorized) {
                Notification.error('Phiên làm việc đã hết hạn, Vui lòng đăng nhập lại');
                store.dispatch({type: 'LOGOUT_ACTION'});
                localStorage.clear();
                return;
            }
            const responseData = response.data;
            const successResponse = cui.isFunction(transformFunc) ? transformFunc(responseData) : responseData;
            successCallBack(successResponse);
        } catch (e) {
            if (cui.isFunction(failCallBack)) {
                failCallBack(handleHttpError(e));
            }
        }
    });
}

const defaultFailCallback = (error) => {
    message.success('Hệ thống đang bận. Vui lòng thử lại sau!');
}

export function getRequest(url, config = {}, successCallBack, failCallBack, transformFunc) {
    transformConfig(config)
    return makeHttpRequest(() => axios.get(url, config), successCallBack, failCallBack ? failCallBack : defaultFailCallback, transformFunc);
}

export function postRequest(url, data, config = {}, successCallBack, failCallBack, transformFunc) {
    // if (cui.isObject(data)) {
    //   data.session = localStorage.getItem('session') || '';
    // }
    transformConfig(config);
    return makeHttpRequest(() => axios.post(url, data, config), successCallBack, failCallBack ? failCallBack : defaultFailCallback, transformFunc);
}

export function postRequestNoLoading(url, data, config = {}, successCallBack, failCallBack, transformFunc) {
    // if (cui.isObject(data)) {
    //   data.session = localStorage.getItem('session') || '';
    // }
    transformConfig(config);
    return makeHttpRequestNoLoading(() => axios.post(url, data, config), successCallBack, failCallBack ? failCallBack : defaultFailCallback, transformFunc);
}

export function putRequest(url, data, config = {}, successCallBack, failCallBack) {
    transformConfig(config);
    return makeHttpRequest(() => axios.put(url, data, config), successCallBack, failCallBack ? failCallBack : defaultFailCallback);
}

export function deleteRequest(url, data, config = {}, successCallBack, failCallBack) {
    transformConfig(config);
    return makeHttpRequest(() => axios.delete(url, data, config), successCallBack, failCallBack ? failCallBack : defaultFailCallback);
}
