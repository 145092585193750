import {PlusOutlined} from '@ant-design/icons';
import {Divider, Form, message, Upload} from 'antd';
import React, {useEffect, useState} from 'react';
import './style.css';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Image must smaller than 5MB!');
  }

  return isJpgOrPng && isLt2M;
}

function UploadFile({ props, clear = false }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (clear) {
      setImageUrl('');
    }
  }, [clear]);

  const handleChangeUploadImage = (info) => {
    const reader = new FileReader();
    const files = info.file.originFileObj;
    if (!files) {
      return;
    }
    reader.onload = () => {
      getImageUrl(reader.result);
    };
    reader.readAsArrayBuffer(files);
  };

  const getImageUrl = (binaryStr) => {
    let binary = '';
    const bytes = new Uint8Array(binaryStr);

    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const data = 'data:image/jpg;base64, ' + btoa(binary);
    setImageUrl(data);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <Form.Item name="image">
        <Upload
          listType="picture-card"
          className="image-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeUploadImage}
          action
        >
          <div className="h-full flex flex-col justify-center py-6">
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
          </div>
        </Upload>
      </Form.Item>
      <Divider />
    </div>
  );
}

export default UploadFile;
