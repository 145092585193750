import {combineReducers} from 'redux';
import {userReducer} from './userReducer';
import {progressReducer} from './progressReducer';
import {projectReducer} from './projectReducer';
import {languageReducer} from './languageReducer';
import {formSurveyReducer} from './formSurveyReducer';
import {UserManageReducer} from 'pages/setting/user-group/components/UserManageReducer';

export const rootReducer = combineReducers({
  userReducer,
  progressReducer,
  projectReducer,
  languageReducer,
  formSurveyReducer,
  UserManageReducer,
});
