import {getRequest, postRequest} from 'utils/http';

const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;
export const displayImageExperienceURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/experience/display-path?path=`;
export const downloadFileExperienceURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/experience/download-path?path=`;
export const uploadFileExperienceURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/experience/upload-get-path`;
export const deleteFileExperienceURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/experience/delete-path?path=`;

export const ExperienceService = {
    getDataPaging: function (filterValue, successCallback, failCallback) {
        const URL = `${baseURL}/experience/list-tab?filterValue=` + filterValue;
        return getRequest(URL, {}, successCallback, failCallback);
    },

    getAllData: function (successCallback, failCallback) {
        const URL = `${baseURL}/experience/all`;
        return getRequest(URL, {}, successCallback, failCallback);
    },

    addNew: function (data, successCallback, failCallback) {
        const URL = `${baseURL}/experience/add`;
        return postRequest(URL, data, {}, successCallback, failCallback);
    },

    update: function (data, successCallback, failCallback) {
        const URL = `${baseURL}/experience/update`;
        return postRequest(URL, data, {}, successCallback, failCallback);
    },

    delete: function (data, successData, failCallback) {
        const URL = `${baseURL}/experience/delete/${data.id}`;
        return postRequest(URL, data, {}, successData, failCallback);
    },

    removeByPath: function (data, successData, failCallback) {
        let path = data?.replaceAll("\\", "/")
        const URL = deleteFileExperienceURL + path;
        return postRequest(URL, {}, {}, successData, failCallback);
    }
}