import React, {useEffect, useState} from 'react';
import './style.scss';
import {Layout, Menu, Tooltip} from 'antd';
import {Link, NavLink} from 'react-router-dom';
import {LIST_PAGE} from 'global/routes';
import logo from 'assets/images/logo_vnpt.svg';
import Cookies from 'universal-cookie';
import {UserService} from 'api/UserService';
import {useDispatch} from 'react-redux';
import {setInfoUser, setMenuPermissions} from 'redux/action/userAction';
import TooltipParagraph from "./TooltipParagraph";
import _ from 'lodash';

const {SubMenu} = Menu;
const {Sider} = Layout;
const cookies = new Cookies();

const SidebarAnt = ({collapsed, setCollapse}) => {
    const [menuList, setMenuList] = useState([]);
    const dispatch = useDispatch();

    function convertToPermission(roleList) {
        let permissionList = {}
        for (let i = 0; i < roleList.length; i++) {
            for (let k = 0; k < roleList[i]?.permissions?.length; k++) {
                let permission = roleList[i].permissions[k]
                let actionList = {
                    VIEW: false,
                    ADD: false,
                    UPDATE: false,
                    DELETE: false,
                    REQ_APPROVE: false,
                    APPROVE: false,
                    IMPORT: false,
                }
                permission.paction.split("|").map(item => {
                    actionList[item] = true
                })
                if (permissionList[permission.purl]) {
                    permission.paction.split("|").map(item => {
                        permissionList[permission.purl][item] = true
                        permissionList[permission.pname][item] = true
                    })
                } else {
                    permission.paction.split("|").map(item => {
                        actionList[item] = true
                    })
                    permissionList[permission.purl] = actionList;
                    permissionList[permission.pname] = actionList;
                }
            }
        }
        console.log(permissionList)
        return permissionList;
    }

    const filterMenuActive = (permissionList, item) => {
        if (item?.children && item?.children.length > 0) {
            item?.children.map((children) => {
                filterMenuActive(permissionList, children);
            })
        } else {
            item.hidden = !permissionList[item.key] || !permissionList[item.key].VIEW;
        }
    }

    useEffect(() => {
        let value = {
            id: cookies.get('idUser'),
        };
        UserService.getUserByID(value, {}, (res) => {
            if (res.code === 0) {
                dispatch(setInfoUser({...res.data}));
                let permissionList = convertToPermission(res.data.roleList);
                dispatch(setMenuPermissions(permissionList));
                let menuActive = _.cloneDeep(LIST_PAGE);
                // if (res.data.orgId !== 'ADMIN_SYSTEM') {
                menuActive.map(item => {
                    filterMenuActive(permissionList, item)
                })
                // }
                setMenuList(menuActive);
            }
        });
    }, []);

    const styleTooltipMenuItem = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: "none",
    }

    const getMenuActive = (item, index) => {
        if (!item?.hidden || item?.hidden === false) {
            if (item?.children && item?.children.length > 0) {
                let isShowParent = false;
                for (let i = 0; i < item?.children.length; i++) {
                    if (!item.children[i]?.hidden || item.children[i]?.hidden === false) {
                        isShowParent = true;
                        break;
                    }
                }
                return (isShowParent && (
                        <SubMenu key={index + item?.path.toString()} icon={item?.icon}
                                 title={
                                     <TooltipParagraph>
                                         {item.title}
                                     </TooltipParagraph>
                                 }>
                            {item?.children.map((children, index) => {
                                return getMenuActive(children, index);
                            })}
                        </SubMenu>)
                );
            } else {
                return (
                    <Menu.Item key={item?.path.toString()} icon={item?.icon}>
                        <Link to={item?.path.toString()}>{item?.title}
                            <Tooltip
                                placement="right"
                                title={item.title}
                            >
                                <div style={styleTooltipMenuItem}/>
                            </Tooltip>
                        </Link>
                    </Menu.Item>
                );
            }
        }
    }

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapse}
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                // height: '100vh',
                height: '95vh',
                position: 'fixed',
                left: 0,
                zIndex: 1000
            }}
            // width={200}
        >
            <NavLink exact to="/" className="block">
                <div className="border-b border-gray-500 mt-4">
                    <div className="flex px-6 pb-0.5">
                        <img className="mr-3 h-8" src={logo} alt="logo"/>
                        <h3 className="text-white text-2xl">CEM</h3>
                    </div>
                </div>
            </NavLink>

            <Menu theme="dark" defaultSelectedKeys={['All_boards']} mode="inline" style={{fontWeight: 600}}>
                {
                    menuList.map((item, index) => {
                        return getMenuActive(item, index)
                    })
                }
            </Menu>
        </Sider>
    );
};

export default SidebarAnt;
