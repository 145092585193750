import moment from 'moment';
import {CHANGE_LOCALE, DEFAULT_LOCALE} from '../constants';

const initialState = {
  locale: localStorage.getItem('language') || DEFAULT_LOCALE,
};

export function languageReducer (state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      moment.locale(action.payload);  // for antd
      return {
        ...state,
        locale: action.payload
      };
    default:
      return state;
  }
};
