import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, Radio, Select} from 'antd';
import {useDispatch} from 'react-redux';
import {addUserModal} from './UserManageAction';
import {UserService} from 'api/UserService';

const { Option } = Select;

const UserAddModalProfile = ({ index, listUserGroup, userInfo, countCheckRole, setCountCheckRole }) => {
  const dispatch = useDispatch();
  const [dataTableRoleList, setDataTableRoleList] = useState();
  const [checkAllRoles, setCheckAllRoles] = useState(true);
  const currentSelectedRoles = useRef([]);

  useEffect(() => {
    let valueData = {
      id: userInfo?.groupList[index]?.id,
    };

    getUGListByID(valueData);
    if (countCheckRole === 0) {
      handleDetailRolesGroupView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listUserGroup]);

  const handleDetailRolesGroupView = () => {
    setCountCheckRole(1);
    if (userInfo?.groupList[index]?.useRoleGroup === 1) {
      setCheckAllRoles(true);
    } else {
      setCheckAllRoles(false);
    }
  };

  const getUGListByID = (valueData) => {
    UserService.getUserGroupListByID(valueData, (res) => {
      if (res.code === 0) {
        setDataTableRoleList(res.data.roleList);
      }
    });
  };

  const checkUseRoleGroup = (value) => {
    let useRoleGroup = 1;
    let temp = [];
    if (value === true) {
      useRoleGroup = 1;
      temp = [
        ...listUserGroup.slice(0, index),
        { ...listUserGroup[index], useRoleGroup: useRoleGroup, roleIdList: [] },
        ...listUserGroup.slice(index + 1),
      ];
    } else {
      useRoleGroup = 0;
      temp = [
        ...listUserGroup.slice(0, index),
        { ...listUserGroup[index], useRoleGroup: useRoleGroup, roleIdList: [] },
        ...listUserGroup.slice(index + 1),
      ];
    }
    dispatch(addUserModal(temp));
    return useRoleGroup;
  };

  const onChangeChooseUserGroup = (value) => {
    let valueData = {
      id: value,
    };
    UserService.getUserGroupListByID(valueData, (res) => {
      if (res.code === 0) {
        setDataTableRoleList(res.data.roleList);
      }
    });
    const temp = [
      ...listUserGroup.slice(0, index),
      { ...listUserGroup[index], groupId: value },
      ...listUserGroup.slice(index + 1),
    ];
    dispatch(addUserModal(temp));
  };
  const onChangeChooseRole = (value) => {
    let useRoleGroup = checkUseRoleGroup(checkAllRoles);
    const temp = [
      ...listUserGroup.slice(0, index),
      { ...listUserGroup[index], groupRole: value.target.value, useRoleGroup: useRoleGroup },
      ...listUserGroup.slice(index + 1),
    ];
    dispatch(addUserModal(temp));
  };
  const checkBoxRoleHandle = (value) => {
    currentSelectedRoles.current = value;
    let useRoleGroup = checkUseRoleGroup(checkAllRoles);
    const temp = [
      ...listUserGroup.slice(0, index),
      { ...listUserGroup[index], useRoleGroup: useRoleGroup, roleIdList: value },
      ...listUserGroup.slice(index + 1),
    ];
    dispatch(addUserModal(temp));
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="grid grid-cols-2 gap-4 pointer-events-none">
        <p className="-m-0"> Đơn vị quản lý</p>
        <Select id={index} onChange={(e) => onChangeChooseUserGroup(e)} value={listUserGroup[index]?.id}>
          {listUserGroup?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.ugName}
            </Option>
          ))}
        </Select>
      </div>
      <div className="grid grid-cols-2 gap-4 pointer-events-none">
        <p className="-m-0">Phạm vi</p>
        <Radio.Group id={index} value={listUserGroup[index]?.urgType} onChange={(e) => onChangeChooseRole(e)}>
          <Radio value={'ALL_MEMBER'}>Đơn vị</Radio>
          <Radio value={'ONLY_YOU'}>Cá nhân</Radio>
        </Radio.Group>
      </div>
      <div className="inline-block">
        <span className="-m-0">Nhóm quyền:</span>
        {dataTableRoleList?.length > 0 && (
          <Checkbox
            className="ml-2 pointer-events-none"
            checked={checkAllRoles}
            onChange={() => {
              setCheckAllRoles(!checkAllRoles);
              checkUseRoleGroup(!checkAllRoles);
            }}
          >
            Tất cả
          </Checkbox>
        )}
      </div>
      {dataTableRoleList?.length > 0 && (
        <Checkbox.Group
          name="checkBoxRole"
          className="grid grid-cols-3 gap-4 mt-2 pt-2 border pointer-events-none"
          disabled={checkAllRoles}
          id={index}
          onChange={(e) => checkBoxRoleHandle(e)}
          defaultValue={userInfo?.groupList[index]?.roleIdList}
        >
          {dataTableRoleList?.map((item) => (
            <Checkbox className="ml-2" key={item.rid} value={item.rid}>
              {item.rname}
            </Checkbox>
          ))}
        </Checkbox.Group>
      )}
    </div>
  );
};

export default UserAddModalProfile;
