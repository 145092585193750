import {ADD_USER_INFO, REMOVE_USER_INFO, GET_INFO, GET_MENU_PERMISSIONS, REMOVE_MENU_PERMISSIONS} from '../constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
  authorized: !!cookies.get('token'),
  data: cookies.get('email') ? { email: cookies.get('email') } : null,
  userInfo: {},
  listMenuPermissions: {},
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case ADD_USER_INFO:
      return {
        ...state,
        authorized: true,
        data: action.payload,
      };
    case REMOVE_USER_INFO:
      return {
        authorized: false,
        data: null,
        userInfo: {},
        listMenuPermissions: {},
      };
    case GET_MENU_PERMISSIONS:
      return {
        ...state,
        listMenuPermissions: action.payload,
      };
    case REMOVE_MENU_PERMISSIONS:
      return {
        ...state,
        listMenuPermissions: null,
      };
    default:
      return state;
  }
}
