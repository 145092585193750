import {lazy} from 'react';
import {
    AreaChartOutlined,
    BarChartOutlined,
    ContainerOutlined,
    ControlOutlined,
    DotChartOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    FileUnknownOutlined,
    FlagOutlined,
    FolderViewOutlined,
    FundOutlined,
    GiftOutlined,
    GoldOutlined,
    GroupOutlined,
    HeartOutlined,
    MailOutlined,
    MehOutlined,
    PieChartOutlined,
    ProjectOutlined,
    RadarChartOutlined,
    SettingOutlined,
    SlackOutlined,
    StopOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    WindowsOutlined,
} from '@ant-design/icons';
import _ from 'lodash';

export const HOME_PAGE = '/';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const CEMI = '/cemi';
export const CEMI_DASHBOARD = CEMI + '/dashboard';
export const CEMI_DASHBOARD_CORPORATIONS = CEMI_DASHBOARD + '/corporations';
export const CEMI_DASHBOARD_GROUP = CEMI_DASHBOARD + '/group';
export const CEMI_SETTINGS = CEMI + '/settings';
export const CEMI_SETTING_KPI_BY_MONTH = CEMI_SETTINGS + '/kpi-by-month';
export const CEMI_SETTING_KPI_CONFIG = CEMI_SETTINGS + '/kpi-config';
export const CEMI_SETTING_KPI_CONFIG_PARAM = CEMI_SETTING_KPI_CONFIG + '/:id/:month/:year/:reportType';
export const CEMI_SETTING_KPI_RESULT = CEMI_SETTINGS + '/kpi-result';
export const CEMI_SETTING_KPI_RESULT_PARAM = CEMI_SETTING_KPI_RESULT + '/:id/:month/:year/:reportType';
export const CEMI_SETTING_SUW_BY_MONTH = CEMI_SETTINGS + '/suw-by-month';
export const CEMI_SETTING_SUW_CONFIG_PARAM = CEMI_SETTING_SUW_BY_MONTH + '/:id/:month/:year';
export const CEMI_SETTING_PROGRAM_GROUP = CEMI_SETTINGS + '/program-group';
export const CEMI_SETTING_PROGRAM_RESULT = CEMI_DASHBOARD + '/cemi-program-result';
export const CEMI_SETTING_PROGRAM_RESULT_PARAMS = CEMI_DASHBOARD + '/cemi-program-result/:proId';
export const CEMI_SETTING_PROGRAM = CEMI_DASHBOARD + '/cemi-program';
export const CEMI_SETTING_PROGRAM_APPROVE = CEMI_SETTING_PROGRAM + '/:status';
export const CEMI_SETTING_PHASE = CEMI_SETTINGS + '/phase';
export const CEMI_SETTING_END_POINT = CEMI_SETTINGS + '/end-point';
export const CEMI_SETTING_UEWEIGHT = CEMI_SETTINGS + '/uew';
export const CEMI_SETTING_UNIT = CEMI_SETTINGS + '/unit';
export const CEMI_SETTING_SERVICE = CEMI_SETTINGS + '/service';
export const CEMI_SETTING_KPI = CEMI_SETTINGS + '/kpi';
export const SURVEY = '/survey';
export const SURVEY_TEMPLATE = SURVEY + '/template';

export const SURVEY_REQUEST = SURVEY + '/request';
export const SURVEY_CAMPAIGN = SURVEY + '/campaign';
export const SURVEY_MEDIA = SURVEY + '/media';
export const SURVEY_REPORT = SURVEY + '/report';
export const SURVEY_REPORT_SURVEY = SURVEY_REPORT + '/survey';
export const SURVEY_REPORT_RESULT = SURVEY_REPORT + '/result';
export const SURVEY_REPORT_USSD = SURVEY_REPORT + '/ussd';
export const SURVEY_REPORT_USSD_BUSY = SURVEY_REPORT + '/ussd-busy';
export const SURVEY_REPORT_USSD_BY_AREA = SURVEY_REPORT + '/ussd-by-area';
export const SURVEY_REPORT_USSD_BY_DEPARTMENT = SURVEY_REPORT + '/ussd-by-department';
export const SURVEY_REPORT_USSD_BY_DEPARTMENT_DETAIL = SURVEY_REPORT + '/ussd-by-department-detail';
export const SURVEY_THANKS = SURVEY + '/thanks';
export const SURVEY_BLACKLIST = SURVEY + '/blacklist';
export const SURVEY_BLACKLIST_LIST = SURVEY_BLACKLIST + '/list';
export const SURVEY_BLACKLIST_HISTORY = SURVEY_BLACKLIST + '/history';
export const SURVEY_THEME = SURVEY + '/theme';
export const SURVEY_LOYALTY = SURVEY + '/loyalty';
export const SURVEY_TYPE = SURVEY + '/survey-type';
export const SURVEY_GROUP = SURVEY + '/survey-group';

export const EXPERIENCE = '/experience';
export const EXPERIENCE_SERVICE = EXPERIENCE + '/service';
export const EXPERIENCE_CAMPAIGN = EXPERIENCE + '/campaign';
export const EXPERIENCE_LIST = EXPERIENCE + '/list';
export const EXPERIENCE_REPORT = EXPERIENCE + '/report';
export const SYSTEM = '/system';
export const SYSTEM_USER = SYSTEM + '/user';
export const SYSTEM_ROLE = SYSTEM + '/role';
export const SYSTEM_USER_GROUP = SYSTEM + '/user-group';
export const SYSTEM_QR_CODE = SYSTEM + '/qr-code';

export const PUBLIC = '/public/experience/:exCampId';
export const FORBIDDEN = '/forbidden';

const SurveyType = lazy(() => import('pages/survey_type/SurveyType'));
const SurveyGroup = lazy(() => import('pages/groupe_type/SurveyGroup'));

const SetTemplate = lazy(() => import('pages/survey/template/TemplateManagement.js'));
const CreateTemplateSurvey = lazy(() => import('pages/survey/template/CreateTemplateSurveyType'));

const SetTheme = lazy(() => import('pages/theme/ThemeManager'));
const Promotion = lazy(() => import('pages/promotion/Promotion'));
const SurveyManagement = lazy(() => import('pages/survey/SurveyManagement'));
const CreateFormSurvey = lazy(() => import('pages/survey/CreateFormSurvey'));
const Statistical = lazy(() => import('pages/statistical/survey/Statistical'));
const StatisticalUSSD = lazy(() => import('pages/statistical/ussd-survey-by-time/StatisticalUSSD'));
const StatisticalUSSDDetail = lazy(() => import('pages/statistical/ussd-survey-by-time/StatisticalUSSDDetail'));
const Request = lazy(() => import('pages/request/Request'));
const GalleryManagement = lazy(() => import('pages/gallerys/GalleryManagement'));
const Thanks = lazy(() => import('pages/thanks/Thanks'));
const Blacklist = lazy(() => import('pages/blacklist/Blacklist'));
const BlacklistAudit = lazy(() => import('pages/blacklist/BlacklistAudit'));
const UserManagement = lazy(() => import('pages/setting/user/UserManagement'));
const RoleManagement = lazy(() => import('pages/setting/role/RoleManagement'));
const QRCodeGeneral = lazy(() => import('pages/setting/qr-code/QRCodeGeneral'));
const UserGroupManagement = lazy(() => import('pages/setting/user-group/UserGroupManagement'));
const ExperienceManagement = lazy(() => import('pages/experience/experience/ExperienceManagement'));
const ServiceManagement = lazy(() => import('pages/experience/service/ServiceManagement'));
const ExperienceCampManagement = lazy(() => import('pages/experience/camp/ExperienceCampManagement'));
const ReportManagement = lazy(() => import('pages/experience/report/ReportManagement'));
const Welcome = lazy(() => import('pages/Welcome/Welcome'));
const NotifyUSSDBusy = lazy(() => import('../pages/statistical/ussd-notify/NotifyUSSDBusy'));
const NotifyUSSDBusyDetail = lazy(() => import('../pages/statistical/ussd-notify/NotifyUSSDBusyDetail'));
const AreaSummaryResponse = lazy(() => import('../pages/statistical/ussd-survey-by-area/AreaSummaryResponse'));
const DepartmentSummaryResponse = lazy(() =>
    import('../pages/statistical/ussd-survey-by-area/DepartmentSummaryResponse')
);
const DepartmentDetailResponse = lazy(() =>
    import('../pages/statistical/ussd-survey-by-area/DepartmentDetailResponse')
);
const CemiCorporations = lazy(() => import('../pages/cemi/dashboard/corporations/CemiCorporations'));
const CemiGroup = lazy(() => import('../pages/cemi/dashboard/group/CemiGroup'));
const CemiKPIConfig = lazy(() => import('../pages/cemi/settings/kpi-config/CemiKPIConfig'));
const CemiKPIResult = lazy(() => import('../pages/cemi/settings/kpi-result/CemiKPIResult'));
const CemiKPIByMonth = lazy(() => import('../pages/cemi/settings/kpi-by-month/CemiKPIByMonth'));
const CemiProgram = lazy(() => import('../pages/cemi/settings/program/CemiProgram'));
const CemiProgramGroup = lazy(() => import('../pages/cemi/settings/program-group/CemiProgramGroup'));
const CemiProgramResult = lazy(() => import('../pages/cemi/settings/program-result/CemiProgramResult'));
const CemiSUWConfig = lazy(() => import('../pages/cemi/settings/suw-config/CemiSUWConfig'));
const CemiKPI = lazy(() => import('../pages/cemi/settings/kpi/CemiKPI'));
const CemiUnit = lazy(() => import('../pages/cemi/settings/unit/CemiUnit'));
const CemiService = lazy(() => import('../pages/cemi/settings/service/CemiService'));
const CemiPhase = lazy(() => import('../pages/cemi/settings/phase/CemiPhase'));
const CemiEndpoint = lazy(() => import('../pages/cemi/settings/end-point/CemiEndpoint'));
const CemiUEWeight = lazy(() => import('../pages/cemi/settings/uew-config/CemiUEWWeight'));
const Page403 = lazy(() => import('../pages/error/Page403'));
const Page404 = lazy(() => import('../pages/error/Page404'));

export const LIST_PAGE_ORIGIN = [
    {
        key: 'home_page',
        path: HOME_PAGE,
        component: Welcome,
        title: 'Welcome',
        icon: <MailOutlined/>,
        action: 'VIEW',
        hidden: true,
        exact: true,
    },
    {
        key: 'cemi',
        path: CEMI,
        title: 'Quản trị TNKH Cemi',
        icon: <FundOutlined/>,
        children: [
            {
                key: 'cemi_dashboard',
                path: CEMI_DASHBOARD,
                title: 'Dashboard',
                icon: <PieChartOutlined/>,
                children: [
                    {
                        key: 'cemi_dashboard_corporations',
                        path: CEMI_DASHBOARD_CORPORATIONS,
                        component: CemiCorporations,
                        title: 'Bộ chỉ số TĐ',
                        action: 'VIEW',
                        icon: <AreaChartOutlined/>,
                    },
                    {
                        key: 'cemi_dashboard_group',
                        path: CEMI_DASHBOARD_GROUP,
                        component: CemiGroup,
                        title: 'Bộ chỉ số TCT',
                        action: 'VIEW',
                        icon: <DotChartOutlined/>,
                    },
                ],
            },
            {
                key: 'cemi_settings',
                path: CEMI_SETTINGS,
                title: 'Thiết lập CEMI',
                icon: <SettingOutlined/>,
                children: [
                    {
                        key: 'cemi_setting_kpi_by_month',
                        path: CEMI_SETTING_KPI_BY_MONTH,
                        component: CemiKPIByMonth,
                        title: 'Bộ chỉ số KPI theo tháng',
                        action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE|IMPORT|RESET_STATUS',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_kpi_config_param',
                        path: CEMI_SETTING_KPI_CONFIG_PARAM,
                        component: CemiKPIConfig,
                        hidden: true,
                        title: 'Cấu hình bộ chỉ số theo tháng',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_kpi_result_param',
                        path: CEMI_SETTING_KPI_RESULT_PARAM,
                        component: CemiKPIResult,
                        hidden: true,
                        title: 'Kết quả thực hiện theo tháng chỉ số theo tháng',
                        action: 'VIEW|UPDATE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_suw_config_param',
                        path: CEMI_SETTING_SUW_CONFIG_PARAM,
                        component: CemiSUWConfig,
                        hidden: true,
                        title: 'Cấu hình trọng số dịch vụ, đơn vị theo tháng',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_phase',
                        path: CEMI_SETTING_PHASE,
                        component: CemiPhase,
                        title: 'Giai đoạn Cemi',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_end_point',
                        path: CEMI_SETTING_END_POINT,
                        component: CemiEndpoint,
                        title: 'Điểm chạm Cemi',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_ueweight',
                        path: CEMI_SETTING_UEWEIGHT,
                        component: CemiUEWeight,
                        title: 'Trọng số điểm chạm gốc theo đơn vị',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_unit',
                        path: CEMI_SETTING_UNIT,
                        component: CemiUnit,
                        title: 'Đơn vị phân giao Cemi',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_service',
                        path: CEMI_SETTING_SERVICE,
                        component: CemiService,
                        title: 'Dịch vụ đo lường Cemi',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_kpi',
                        path: CEMI_SETTING_KPI,
                        component: CemiKPI,
                        title: 'Chỉ số KPI',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_program_group',
                        path: CEMI_SETTING_PROGRAM_GROUP,
                        component: CemiProgramGroup,
                        title: 'Nhóm chương trình hành động',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_program',
                        path: CEMI_SETTING_PROGRAM,
                        component: CemiProgram,
                        title: 'Chương trình hành động',
                        action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE|RESET_STATUS',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_program_approved',
                        path: CEMI_SETTING_PROGRAM_APPROVE,
                        component: CemiProgram,
                        title: 'Phê duyệt chương trình hành động',
                        action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_program_result',
                        path: CEMI_SETTING_PROGRAM_RESULT,
                        component: CemiProgramResult,
                        title: 'Báo cáo CTHĐ',
                        action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE|REQ_APPROVE_2LEVEL|APPROVE_2LEVEL|RESET_STATUS',
                        icon: <RadarChartOutlined/>,
                    },
                    {
                        key: 'cemi_setting_program_result_params',
                        path: CEMI_SETTING_PROGRAM_RESULT_PARAMS,
                        component: CemiProgramResult,
                        hidden: true,
                        title: 'Báo cáo CTHĐ',
                        action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE|REQ_APPROVE_2LEVEL|APPROVE_2LEVEL',
                        icon: <RadarChartOutlined/>,
                    },
                ],
            },
        ],
    },
    {
        key: 'survey',
        path: SURVEY,
        title: 'Khảo sát khách hàng',
        icon: <PieChartOutlined/>,
        children: [
            {
                key: 'survey_request',
                path: SURVEY_REQUEST,
                component: Request,
                title: 'Gửi yêu cầu khảo sát',
                action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE',
                icon: <MailOutlined/>,
                exact: true,
            },
            {
                key: 'survey_campaign',
                path: SURVEY_CAMPAIGN,
                component: SurveyManagement,
                title: 'Chiến dịch khảo sát',
                action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE',
                icon: <FlagOutlined/>,
            },
            {
                key: 'survey_media',
                path: SURVEY_MEDIA,
                component: GalleryManagement,
                title: 'Quản lý file đa phương tiện',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <FolderViewOutlined/>,
            },
            {
                key: 'survey_report',
                path: SURVEY_REPORT,
                title: 'Thống kê & báo cáo',
                icon: <BarChartOutlined/>,
                children: [
                    {
                        key: 'survey_report_survey',
                        path: SURVEY_REPORT_SURVEY,
                        component: Statistical,
                        title: 'Thống kê khảo sát khách hàng',
                        action: 'VIEW',
                        icon: <BarChartOutlined/>,
                    },
                    // {
                    //   key: 'survey_report_result',
                    //   path: SURVEY_REPORT_RESULT,
                    //   component: Statistical,
                    //   title: 'Thống kê khảo sát',
                    //   hidden: true,
                    //   action: 'VIEW',
                    //   icon: <BarChartOutlined />,
                    // },
                    {
                        key: 'survey_report_ussd',
                        path: SURVEY_REPORT_USSD,
                        component: StatisticalUSSD,
                        title: 'Thống kê USSD',
                        action: 'VIEW',
                        icon: <BarChartOutlined/>,
                    },
                    {
                        key: 'survey_report_ussd_busy',
                        path: SURVEY_REPORT_USSD_BUSY,
                        component: NotifyUSSDBusy,
                        title: 'Thống kê sự cố qua USSD',
                        action: 'VIEW',
                        icon: <BarChartOutlined/>,
                    },
                    {
                        key: 'survey_report_ussd_by_area',
                        path: SURVEY_REPORT_USSD_BY_AREA,
                        component: AreaSummaryResponse,
                        title: 'Báo cáo tổng hợp đánh giá từ khách hàng theo phòng ban',
                        action: 'VIEW',
                        icon: <BarChartOutlined/>,
                    },
                    {
                        key: 'survey_report_ussd_by_department',
                        path: SURVEY_REPORT_USSD_BY_DEPARTMENT,
                        component: DepartmentSummaryResponse,
                        title: 'Báo cáo tổng hợp đánh giá theo ĐTV',
                        action: 'VIEW',
                        icon: <BarChartOutlined/>,
                    },
                    {
                        key: 'survey_report_ussd_by_department_detail',
                        path: SURVEY_REPORT_USSD_BY_DEPARTMENT_DETAIL,
                        component: DepartmentDetailResponse,
                        title: 'Chi tiết đánh giá theo ngày',
                        action: 'VIEW',
                        icon: <BarChartOutlined/>,
                    },
                ],
            },
            {
                key: 'survey_thanks',
                path: SURVEY_THANKS,
                component: Thanks,
                title: 'Lời cảm ơn',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <HeartOutlined/>,
            },
            {
                key: 'survey_blacklist',
                path: SURVEY_BLACKLIST,
                title: 'Blacklist',
                icon: <StopOutlined/>,
                children: [
                    {
                        key: 'survey_blacklist_list',
                        path: SURVEY_BLACKLIST_LIST,
                        component: Blacklist,
                        title: 'Danh sách thuê bao',
                        action: 'VIEW|ADD|UPDATE|DELETE',
                        icon: <StopOutlined/>,
                    },
                    {
                        key: 'survey_blacklist_history',
                        path: SURVEY_BLACKLIST_HISTORY,
                        component: BlacklistAudit,
                        title: 'Tra cứu lịch sử',
                        action: 'VIEW',
                        icon: <SlackOutlined/>,
                    },
                ],
            },
            {
                key: 'survey_theme',
                path: SURVEY_THEME,
                component: SetTheme,
                title: 'Cấu hình theme khảo sát',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <WindowsOutlined/>,
            },
            {
                key: 'survey_template',
                path: SURVEY_TEMPLATE,
                component: SetTemplate,
                title: 'Cấu hình template khảo sát',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <ContainerOutlined/>,
            },
            {
                key: 'survey_loyalty',
                path: SURVEY_LOYALTY,
                component: Promotion,
                title: 'Quà tặng & tích điểm',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <GiftOutlined/>,
            },
            {
                key: 'survey_type',
                path: SURVEY_TYPE,
                component: SurveyType,
                title: 'Quản lý phân loại thông tin',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <ControlOutlined/>,
            },
            {
                key: 'survey_group',
                path: SURVEY_GROUP,
                component: SurveyGroup,
                title: 'Quản lý nhóm khảo sát',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <GroupOutlined/>,
            },
        ],
    },
    {
        key: 'experience',
        path: EXPERIENCE,
        title: 'Trải nghiệm sản phẩm',
        icon: <FileDoneOutlined/>,
        children: [
            {
                key: 'experience_service',
                path: EXPERIENCE_SERVICE,
                component: ServiceManagement,
                title: 'Danh sách sản phầm trải nghiệm',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <FileTextOutlined/>,
            },
            {
                key: 'experience_campaign',
                path: EXPERIENCE_CAMPAIGN,
                component: ExperienceCampManagement,
                title: 'Chiến dịch trải nghiệm người dùng',
                action: 'VIEW|ADD|UPDATE|DELETE|REQ_APPROVE|APPROVE',
                icon: <FileUnknownOutlined/>,
            },
            {
                key: 'experience_list',
                path: EXPERIENCE_LIST,
                component: ExperienceManagement,
                title: 'Phản hồi trải nghiệm sản phẩm',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <FileSearchOutlined/>,
            },
            {
                key: 'experience_report',
                path: EXPERIENCE_REPORT,
                component: ReportManagement,
                title: 'Báo cáo thống kê trải nghiệm',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <AreaChartOutlined/>,
                hidden: true,
            },
        ],
    },
    {
        key: 'system',
        path: SYSTEM,
        title: 'Quản trị hệ thống',
        icon: <ProjectOutlined/>,
        children: [
            {
                key: 'system_user',
                path: SYSTEM_USER,
                component: UserManagement,
                title: 'Quản lý người dùng',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <UserOutlined/>,
            },
            {
                key: 'system_role',
                path: SYSTEM_ROLE,
                component: RoleManagement,
                title: 'Quản lý quyền',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <GoldOutlined/>,
            },
            {
                key: 'system_user_group',
                path: SYSTEM_USER_GROUP,
                component: UserGroupManagement,
                title: 'Quản lý đơn vị',
                action: 'VIEW|ADD|UPDATE|DELETE',
                icon: <UsergroupAddOutlined/>,
            },
            {
                key: 'system_qr_code',
                path: SYSTEM_QR_CODE,
                component: QRCodeGeneral,
                title: 'Tạo QR Code',
                action: 'VIEW',
                icon: <UsergroupAddOutlined/>,
            },
        ],
    },
    {
        key: 'NotifyUSSDBusyDetail',
        path: '/notifyUSSDBusyDetail/:id',
        component: NotifyUSSDBusyDetail,
        title: 'Chi tiết báo cáo',
        hidden: true,
        icon: <BarChartOutlined/>,
    },

    {
        key: 'Edit_FormSurvay',
        path: '/survey/edit/:id',
        component: CreateFormSurvey,
        title: 'Tạo khảo sát',
        icon: <MehOutlined/>,
        hidden: true,
    },
    {
        key: 'Statistical_USSD_Detail',
        path: '/statisticalUSSD/:id',
        component: StatisticalUSSDDetail,
        title: 'Chi tiết báo cáo',
        icon: <MehOutlined/>,
        hidden: true,
    },
    {
        key: 'Edit_FormSurvay',
        path: '/survey/detail/:id',
        component: CreateFormSurvey,
        title: 'Tạo khảo sát',
        icon: <MehOutlined/>,
        hidden: true,
    },
    {
        key: 'Create_form',
        path: '/create-form',
        component: CreateFormSurvey,
        title: 'Tạo yêu cầu khảo sát',
        icon: <MailOutlined/>,
        hidden: true,
    },
    {
        key: 'Create_Template',
        path: '/create-template',
        component: CreateTemplateSurvey,
        title: 'Tạo template khảo sát',
        icon: <MailOutlined/>,
        hidden: true,
    },
    {
        key: 'Detail_Template',
        path: '/survey/template-detail/:id',
        component: CreateTemplateSurvey,
        title: 'Tạo template khảo sát',
        icon: <MailOutlined/>,
        hidden: true,
    },
    {
        key: 'Edit_Template',
        path: '/survey/template-edit/:id',
        component: CreateTemplateSurvey,
        title: 'Tạo template khảo sát',
        icon: <MailOutlined/>,
        hidden: true,
    },
    {
        key: 'page_403',
        path: FORBIDDEN,
        component: Page403,
        title: 'Page403',
        hidden: true,
        exact: true,
    },
    {
        key: 'page_404',
        path: '*',
        component: Page404,
        title: 'Page404',
        hidden: true,
        exact: true,
    },
];

export const LIST_PAGE = _.cloneDeep(LIST_PAGE_ORIGIN).filter((item) => item?.hidden !== true);

export default LIST_PAGE_ORIGIN;
