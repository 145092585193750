import { getRequest, postRequest } from 'utils/http';

const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

const transformGalleryList = function (response) {
  if (response.code === 0) {
    const list = response.data.map((item, index) => {
      return {
        ...item,
        linkImg: `${baseURL}/gallery/display/${item.id}`,
        key: index,
      };
    });
    response.galleryTransformList = list;
  }
  return response;
};

export const GalleryManagementService = {
  getListGallery: function (successCallback, failCallback) {
    const URL = `${baseURL}/gallery/all`;
    return getRequest(URL, {}, successCallback, failCallback, transformGalleryList);
  },

  getListGallerySystem: function (successCallback, failCallback) {
    const URL = `${baseURL}/gallery/all/system`;
    return getRequest(URL, {}, successCallback, failCallback, transformGalleryList);
  },

  uploadFileGallery: function (data, successCallback, failCallback) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const URL = `${baseURL}/gallery/upload`;
    return postRequest(URL, data, config, successCallback, failCallback);
  },

  udpateGallery: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/gallery/update`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  deleteGallery: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/gallery/delete/${data.id}`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  uploadGetPathGallery: function (data, successCallback, failCallback) {
    // const config = {
    // 	headers: { 'Content-Type': "multipart/form-data" }
    // }
    const URL = `${baseURL}/gallery/upload-get-path`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },
};
