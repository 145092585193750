import {
  CHANGE_AIS_REQUIRE,
  CHANGE_CHECKBOX_QUES_SURVEY,
  CHANGE_CONSTANTS_SURVEY,
  CHANGE_LINEAR_RANGE,
  CHANGE_LIST_CHECKBOX_QUES_SURVEY,
  CHANGE_LIST_RADIO_QUES_SURVEY,
  CHANGE_QUESTION_SURVEY,
  CHANGE_RADIO_QUES_SURVEY,
  CHANGE_STAR,
  CHANGE_SECTION_SURVEY,
  CHANGE_CONSTANTS_SECTION_SURVEY,
} from '../constants';

const initialState = {
  listQuestionSurvey: [
    {
      index: 1,
      qisRequire: '',
      qlanguage: 'vn',
      qcontent: 'Câu hỏi',
      qtid: '',
      id: null,
      data: [],
      obj: { row: [], col: [] },
      aisRequire: 0,
    },
  ],
  listChappter: [],
  constants: 1,
  dataCheckBoxQuestion: [{ value: 1, label: 'This is label' }],
  dataRadioQuestion: [{ value: 1, label: 'This is label' }],
  listMultipleRadioQuestion: {
    row: [{ label: 'Hàng 0' }, { label: 'Hàng 1' }],
    col: [{ label: 'Cột 1' }, { label: 'Cột 2' }],
  },
  listMultipleCheckBoxQuestion: {
    row: [{ label: 'Hàng 0' }, { label: 'Hàng 1' }],
    col: [{ label: 'Cột 1' }, { label: 'Cột 2' }],
  },
  listLinearRange: [],
  listStar: [{ label: 'label' }],
};

export function formSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_QUESTION_SURVEY:
      return {
        ...state,
        listQuestionSurvey: action.payload,
      };
    case CHANGE_SECTION_SURVEY:
      return {
        ...state,
        listChappter: action.payload,
      };
    case CHANGE_CONSTANTS_SURVEY:
      return {
        ...state,
        constants: state.constants + 1,
      };
    case CHANGE_CONSTANTS_SECTION_SURVEY:
      return {
        ...state,
        sectionContants: state.sectionContants + 1,
      };
    case CHANGE_CHECKBOX_QUES_SURVEY:
      return {
        ...state,
        dataCheckBoxQuestion: action.payload,
      };
    case CHANGE_RADIO_QUES_SURVEY:
      return {
        ...state,
        dataRadioQuestion: action.payload,
      };
    case CHANGE_LIST_RADIO_QUES_SURVEY:
      return {
        ...state,
        listMultipleRadioQuestion: action.payload,
      };
    case CHANGE_LIST_CHECKBOX_QUES_SURVEY:
      return {
        ...state,
        listMultipleCheckBoxQuestion: action.payload,
      };
    case CHANGE_LINEAR_RANGE:
      return {
        ...state,
        listLinearRange: action.payload,
      };
    case CHANGE_STAR:
      return {
        ...state,
        listStar: action.payload,
      };
    case CHANGE_AIS_REQUIRE:
      return {
        ...state,
        listQuestionSurvey: action.payload,
      };
    default:
      return state;
  }
}
