import React from 'react';
import {Button, Modal} from 'antd';
import PropTypes from 'prop-types';

const KCSModal = ({ isOpenModal, title, content, closeButton, closeModal, confirmButton, confirmAction, size, isHideFooter }) => {
  let btn = [
    <Button type="primary" key="ok" onClick={confirmAction || closeModal}>{confirmButton || 'OK'}</Button>
  ]

  if (closeButton) {
    btn = [<Button key="cancel" onClick={closeModal}>{closeButton === true ? 'Đóng' : closeButton}</Button>, ...btn];
  }

  return (
    <Modal
      className="modal-wrapper"
      title={title || 'Thông báo'}
      visible={isOpenModal}
      onCancel={closeModal}
      footer={isHideFooter ? null : btn}
      width={size === 'xxl' ? 1350 : size === 'xl' ? 1150 : size === 'lg' ? 800 : 520}
    >
      {content}
    </Modal>
  );
};

KCSModal.propTypes = {
  title: PropTypes.string,
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmButton: PropTypes.string,
  confirmAction: PropTypes.func,
};

KCSModal.defaultProps = {
  isOpenModal: false,
  closeButton: true,
  closeModal: () => { },
};

export default React.memo(KCSModal);
