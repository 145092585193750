import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'antd';

const SurveyFormModal = ({ isOpenModal, title, content, closeButton, closeModal, confirmButton, confirmAction, size, formValueEdit, contentFooter, formCustom }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    if (formValueEdit) {
      form.setFieldsValue({ ...formValueEdit, viewDataGroup: formValueEdit.viewDataGroup === "ALL_GROUP" ? true : false, dataTableRoleListCheck: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValueEdit])
  return (
    <Modal
      className="form-modal"
      title={title}
      visible={isOpenModal}
      onCancel={closeModal}
      footer={null}
      width={size === "xl" ? 1150 : size === "lg" ? 800 : 520}
      forceRender
    >
      <Form onFinish={confirmAction} form={formCustom ? formCustom : form}>
        <div>{content}</div>
        <div className="flex justify-center space-x-4 mt-6">
          <Button key="cancel" onClick={closeModal}>{closeButton || 'Đóng'}</Button>
          <Form.Item style={confirmAction ? '' : { display: "none" }}>
            <Button key="ok" type="primary" htmlType="submit">{confirmButton || 'OK'}</Button>
          </Form.Item>
          {contentFooter}
        </div>
      </Form>
    </Modal>
  );
};

export default React.memo(SurveyFormModal);
