import {Layout} from 'antd';
import {ProgressTurn} from 'components/common';
import Header from 'components/page/mainpage/Header';
import Sidebar from 'components/page/mainpage/Sidebar';
import React, {Suspense, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import LIST_PAGE_ORIGIN from '../global/routes';

const {Content, Footer} = Layout;

const MainPage = () => {
    const [collapsed, setCollapse] = useState(false);

    const registerPath = (item) => {
        if (item?.children && item.children.length > 0) {
            return item.children.map(function (element) {
                return registerPath(element)
            });
        } else {
            return (
                <Route
                    key={item.path.toString()}
                    exact={item.exact ?? true}
                    path={item.path}
                    component={item.component}
                />
            );
        }
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sidebar collapsed={collapsed} setCollapse={setCollapse}/>
            <Layout className="text-primary" style={{marginLeft: collapsed ? 80 : 200}}>
                <Header/>
                <Content style={{margin: '0 16px'}}>
                    <div className={`${'bg-gray-50'} p-6 min-h-full `}>
                        <Suspense fallback={<ProgressTurn show/>}>
                            <Switch>
                                {LIST_PAGE_ORIGIN.map((item) => {
                                    return registerPath(item);
                                })}
                            </Switch>
                        </Suspense>
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>© Bản Quyền thuộc Tập đoàn Bưu chính Viễn thông Việt Nam</Footer>
            </Layout>
        </Layout>
    );
};

export default React.memo(MainPage);
